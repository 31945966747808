<template>
  <div class="home-container">
    <router-view :isAccountOwner="isAccountOwner" :isTeacher="isTeacher" :isInstituteAdmin="isInstituteAdmin"
                 :isStudent="isStudent" :currentAssociation="currentAssociation" :guruInitialised="guruInitialised"/>
  </div>
</template>

<script>
import { GURU_ROLES, GURU_USER_STATUS } from '../../assets/javascript/constants'

export default {
  name: 'GuruInstitute',
  props: ['associations', 'guruInitialised'],
  inject: ['$validator'],
  data: function () {
    return {
      linkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        }
      ]
    }
  },
  methods: {

  },
  computed: {
    isAccountOwner () {
      for (let associationType in this.currentAssociation.associationTypes) {
        if (this.currentAssociation.associationTypes[associationType] === GURU_ROLES.ACCOUNT_OWNER) {
          return true
        }
      }

      return false
    },
    isInstituteAdmin () {
      for (let associationType in this.currentAssociation.associationTypes) {
        if (this.currentAssociation.associationTypes[associationType] === GURU_ROLES.INSTITUTE_ADMIN) {
          return true
        }
      }

      return false
    },
    isTeacher () {
      for (let associationType in this.currentAssociation.associationTypes) {
        if (this.currentAssociation.associationTypes[associationType] === GURU_ROLES.TEACHER) {
          return true
        }
      }

      return false
    },
    isStudent () {
      for (let associationType in this.currentAssociation.associationTypes) {
        if (this.currentAssociation.associationTypes[associationType] === GURU_ROLES.STUDENT) {
          return true
        }
      }

      return false
    },
    currentAssociation () {
      if (this.guruInitialised) {
        return _.find(this.associations, { institute: { code: this.$route.params.instituteCode } })
      }

      return {}
    }
  },
  watch: {
    currentAssociation () {
      if (!this.guruInitialised) {
        return
      }

      let location = '/guru/ins/' + this.$route.params.instituteCode

      if (!this.currentAssociation) {
        this.$router.push('/guru')
      } else if (this.currentAssociation.status !== GURU_USER_STATUS.ACTIVE && this.$route.fullPath !== location) {
        this.$router.push(location)
      }
    }
  }
}
</script>
<style lang="scss">
  .panel-heading {
    font-family: serif;
    font-size: 16px;
    letter-spacing: 1px;
    color: #333;
  }

  .panel-block {
    display: block;
  }

  .guru-main-block {
    padding: 0.75em;
    font-size: 0.9em;

    div {
      padding: 0.2em;
    }
  }

  th, td {
    font-size: 0.9em;
  }
</style>
